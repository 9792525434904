<template>
  <div class="clients-container">
    <modal-vue @onChange="onChangeModal" :modal="modal">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Delete Product</p>
          <div class="modal-data">
            <p v-if="showWarning">
            This product will impact on few subsriptions.
          </p>
          <p>
            Are you sure you want to delete this product (<b>{{deleteProductObject.product_name}}</b>) ?
          </p>
          <div class="actions">
            <b-button @click="onChangeModal(false)">Cancel</b-button>
            <b-button @click="removeProduct">Delete</b-button>
          </div>
          </div>
        </div>
      </div>
    </modal-vue>
    <admin-header-vue
      v-if="showProduct"
      alias="Product"
      @onCreate="onCreate"
      pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :dateFormat="dateFormat"
        :canDelete="true"
        :data="productListWithCategory"
        :sortObj="sort"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :titleData="'Products'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @onDelete="onDelete"
        @updateLimit="updateLimit">
        <dropdown-wrap-vue
          @onClickOutside="onClickOutside"
          class="column is-4 form-group">
          <div class="relative">
            <b-input
              type="search"
              ref="categories"
              @focus="onFocus"
              v-model="searchCategory"
              :placeholder="'Select Categories'"
              :icon-right="openCategories ? 'menu-up' : 'menu-down'"
              class="form-control" />
            <common-selection-vue
              :searchText="searchCategory"
              name="category_name"
              @focus="onFocus"
              :open="openCategories"
              :selected="selectedCategories"
              @onChange="onChangeCategoryId"
              :ref="'commonSelection'"
              :list="localSelectedCategories" />
          </div>
        </dropdown-wrap-vue>
        </table-vue>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import { metaTitle } from '../../../../constant/data'
import ModalVue from '../../../Admin/components/Modal.vue'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../components/Dropdowns/CommonSelection.vue'

/** This component contains the code that allows you to view the industries present in the database and their details. From here the user can view/edit existing industries and navigate to the *Add Product* page. */
export default {
  name: 'Products',
  metaInfo () {
    return {
      title: metaTitle.products
    }
  },
  components: {
    tableVue,
    AdminHeaderVue,
    ModalVue,
    DropdownWrapVue,
    CommonSelectionVue
  },
  data () {
    return {
      labels: [{ name: 'Product Id', alias: 'product_id' }, { name: 'Product Name', alias: 'product_name' }, { name: 'Categories', alias: 'category' }, { name: 'Created At', alias: 'created_at' }, { name: 'Updated At', alias: 'updated_at' }],
      dateFormat: ['created_at', 'updated_at'],
      sortValidation: ['product_id', 'category', 'product_name', 'created_at', 'updated_at'],
      search: '',
      sort: { by: 'asc', field: 'product_id' },
      categoryFilterNeeded: true,
      blankOptionRequired: true,
      modal: false,
      deleteProductObject: {},
      showWarning: false,
      selectedCategories: [],
      localSelectedCategories: [],
      openCategories: false,
      searchCategory: null
    }
  },
  computed: {
    ...mapState('products', ['productListWithCategory', 'totalCount']),
    ...mapState('categories', ['categoryList']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    showProduct () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.products.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.products.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.products.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.products.currentPage = newPage
      }
    }
  },
  watch: {
    categoryList () {
      const allCategories = JSON.parse(JSON.stringify(this.categoryList))
      allCategories.push({ category_id: null, category_name: '(Blank)' })
      this.localSelectedCategories = allCategories
      this.selectedCategories = this.localSelectedCategories
    },
    selectedCategories () {
      if (!this.selectedCategories.length) {
        this.resetProducts()
      }
      this.getData()
    }
  },
  mounted () {
    this.getCategoryList()
  },
  methods: {
    ...mapActions('categories', ['getCategoryList']),
    ...mapActions('products', ['getProductListWithCategory', 'resetProducts', 'deleteProduct', 'deleteProductVerify']),
    /** Not sure when this is being used. */
    onCategoryUpdated (data) {
      if (!data.length) {
        this.resetProducts()
      }
      this.getData(data)
    },
    /** This method closes the dropdown if a click is detected anywhere outside the boundary of the dropdown menu
     * @public
     */
    onClickOutside () {
      this.openCategories = false
    },
    /** This method opens the category dropdown if a click is detected on the input
     * @public
     */
    onFocus () {
      this.$refs.categories.$el.firstChild.focus()
      this.openCategories = true
    },
    /** This method redirects the user to the *Update Product* page
     * @public
     */
    onUpdate (data) {
      this.$router.push(`product/update/${data.product_id}`)
    },
    /** This method updates the list of selected categories. Only the products of selected categories will be shown in the table
     * @public
     */
    onChangeCategoryId (categories) {
      this.selectedCategories = categories
    },
    /** This method selects the product to be deleted, and triggers the opening of a confirmation modal.
     * @param data {Object} - The product to be deleted
     * @public
     */
    async onDelete (data) {
      this.deleteProductObject = data
      const res = await this.deleteProductVerify(this.deleteProductObject.product_id)
      if (res === 200) {
        this.showWarning = true
      } else if (res === 201) {
        this.showWarning = false
      }
      this.modal = true
    },
    /** This method toggles the visibility of the modal
     * @public
     */
    onChangeModal (value) {
      this.modal = value
    },
    /** This method deletes the product from the database and refreshes the table
     * @public
     */
    async removeProduct () {
      const res = await this.deleteProduct(this.deleteProductObject.product_id)
      if (res === 200) {
        this.modal = false
        this.getData()
      }
    },
    /** This method redirects the user to the *Add/Create Topic* page
     * @public
     */
    onCreate () {
      this.$router.push('product/add')
    },
    /** This method updates the sorting criteria of the table
     * @public
     */
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    /** This method updates the table page that is being viewed
     * @public
     */
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    /** This method updates the number of rows that are being shown by the table
     * @public
     */
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    /** This method updates the search keyword and triggers the *getData()* method
     * @param text {String} - The new search keyword
     */
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    /** This method fetches the product related data from the database, using the limit/search/sort parameters specified */
    getData () {
      if (this.selectedCategories.length) {
        this.getProductListWithCategory({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search, categoryList: this.selectedCategories.map(e => e.category_id) })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  margin: 20px 0px;
  border-radius: 4px;
  background: var(--primary);
  .relative {
      position: relative;
    }
  .user-delete-modal {
    display: flex;
    justify-content: center;
    .user-delete-inside {
      display: flex;
      color: var(--secondary-text-color);
      font-family: Quicksand;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 400px;
      height: 200px;
      padding: 25px;
      .modal-data {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    p {
      font-size: 14px;
    }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }

    }
    .actions {
      padding-top: 20px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7F8FA466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #FF0000;
        color: white;
        &:hover {
          background-color: #F64A4A;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #EE1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
}
</style>
